@charset "UTF-8";
@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
.my-modal {
  width: fit-content !important;
}

.ng-dropdown-panel .scroll-host {
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.05);
}

.ng-value-container .ng-value {
  white-space: normal !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left:hover {
  background-color: #c5c5c5;
}

.ng-select.custom {
  border: 0px;
  border-radius: 0;
  font-weight: 400;
}

.ng-select.custom .ng-select-container {
  border-radius: 0;
  min-height: 40px;
  width: 90% !important;
}
@media all and (max-width: 465px) {
  .ng-select.custom .ng-select-container {
    width: 100% !important;
  }
}

.ng-select .ng-select-container {
  border-radius: 0px;
  border: 0.5px solid #e5e5e5;
  color: #9d9d9d;
}

.ng-dropdown-panel.ng-select-bottom,
.ng-dropdown-panel.ng-select-top {
  width: 90%;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder,
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  font-size: 16px;
  font-weight: 400;
  padding-top: 7px;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  color: #505050;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  color: white !important;
  background-color: #157af3;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option {
  color: #9d9d9d;
  font-size: 16px;
  font-weight: 400;
  padding-top: 2px;
  padding-bottom: 4px;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option:hover {
  background-color: #157af3;
  color: white;
}

.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-right: 1px solid #c5c5c5;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  color: #505050;
  background-color: #dfdfdf;
}

.ng-select .ng-arrow-wrapper {
  margin-top: 5px;
}
.ng-select .ng-arrow {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  border-color: inherit !important;
  border-width: 0 !important;
  font-size: 16px;
  top: 0 !important;
  left: -3px;
  color: inherit;
  text-align: center;
  transform: rotate(0deg);
  transform-origin: 8px 12px;
  transition: transform 0.2s ease;
}
.ng-select .ng-arrow:before {
  content: "";
}
.ng-select.ng-select-opened .ng-arrow:before {
  content: "";
}

.pure-checkbox input[type=checkbox] + label {
  position: relative;
  padding-left: 0em;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  margin: 0;
  font-weight: 300;
}

.dropdown-list ul li:first-child {
  padding-top: 0px !important;
}

.dropdown-list ul li:last-child {
  padding-bottom: 0px !important;
}

.dropdown-menu {
  padding: 0px;
  border-radius: 0px;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.18);
}

.dropdown-item {
  font-weight: normal;
  display: block;
  white-space: nowrap;
  min-height: 1.2em;
  padding: 7px 16px;
}

.dropdown-item.active {
  background-color: #157af3;
}

.datatable-row-group {
  cursor: pointer !important;
}

input[type=date]:invalid::-webkit-datetime-edit {
  color: #777777 !important;
}

::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
  opacity: 0.7 !important;
}

::-webkit-scrollbar-track {
  background-color: #f4f4f4 !important;
}

::-webkit-scrollbar-thumb {
  background-color: #c5c5c5 !important;
  border-radius: 4px !important;
}

.bs-datepicker-container {
  padding: 2px;
  background: #ffffff;
  color: #333333;
  border: 1px solid #c5c5c5;
  z-index: 99;
}

::ng-deep bs-datepicker-container,
::ng-deep bs-daterangepicker-container {
  z-index: 9999999;
}

.bs-datepicker-head {
  border: 1px solid #dddddd;
  background: #e9e9e9 !important;
  color: #333334 !important;
  font-weight: bold;
}

.bs-datepicker-head button {
  color: #333333;
}

.bs-datepicker-body table th {
  font-size: 0.9em;
  color: #000000;
  font-weight: bold;
  padding: 0.7em 0.3em;
  text-align: center;
}

.bs-datepicker-body table td {
  background: transparent;
  font-weight: normal;
  color: #454545 !important;
  padding: 0.2em;
  font-size: 0.9em;
}

.bs-datepicker-body table td span {
  border-radius: 0%;
}

::ng-deep #toast-container {
  z-index: 99999999;
}

.theme-default .bs-datepicker-body table td span.selected,
.theme-default .bs-datepicker-body table td.selected span,
.theme-default .bs-datepicker-body table td span[class*=select-]:after,
.theme-default .bs-datepicker-body table td[class*=select-] span:after {
  border: 1px solid #003eff;
  background: #007fff;
  font-weight: normal;
  color: #ffffff;
}

.custom-today-class {
  background-color: yellow;
}

.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  overflow-wrap: break-word;
  text-align: center;
  padding: 0.5rem 0.1rem;
  vertical-align: top;
  border-top: 0;
  transition: width 0.3s ease;
  font-size: 14px;
  font-weight: 600;
  color: #1c1c1c;
  overflow: unset !important;
  text-overflow: unset !important;
  white-space: normal !important;
}

.ngx-datatable.material {
  background: unset !important;
  box-shadow: unset !important;
  overflow: visible;
}

.ngx-datatable.material .datatable-header .datatable-header-cell {
  text-align: center;
  padding: 0px;
  font-weight: 400;
  background-color: #fafafa;
  color: #9d9d9d;
  vertical-align: bottom;
  font-size: 16px;
  font-weight: 600;
}

.ngx-datatable .datatable-header .datatable-header-cell .datatable-header-cell-template-wrap .ngx-datatable .datatable-header .datatable-header-cell .sort-btn {
  cursor: pointer;
  display: inline-block;
  line-height: 100%;
  vertical-align: middle;
  width: calc(100%);
}

.ngx-datatable.material .datatable-footer {
  border-top: none;
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.54);
  width: 100%;
}

@media (max-width: 768px) {
  .ngx-datatable.material .datatable-footer {
    width: 100vw;
  }
}
.ngx-datatable.material .datatable-header {
  overflow: visible;
  border-bottom: none;
}

.datatable-icon-sort-unset::before {
  content: "e";
  font-size: 20px;
  color: #1c1c1c;
}

.datatable-icon-up::before {
  content: "e";
  font-size: 20px;
  color: #1c1c1c;
}

.datatable-icon-down::before {
  content: "f";
  font-size: 20px;
  color: #1c1c1c;
}

.datatable-footer-inner {
  font-size: 16px;
  font-weight: 600;
}

.datatable-scroller,
.datatable-body-row {
  width: 100% !important;
  border-bottom: none;
}

.ngx-datatable.material .datatable-footer .page-count {
  padding: 0 0.2rem !important;
}

p,
div,
h1,
h2,
h3,
h4,
h5,
h6,
input,
label,
area,
textarea {
  -webkit-line-break: after-white-space;
}

.toastr-link {
  text-decoration: underline;
  cursor: pointer;
  margin-top: 10px;
  color: black !important;
  font-weight: 500;
}

.custom_toaster .ngx-toastr {
  opacity: 1;
  padding: 22px 35px;
  background: #49E35C;
  font-weight: 400;
  font-size: 20px;
  line-height: 33px;
  right: 30px;
  top: 30px;
}

.custom_toaster button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  position: absolute;
  bottom: -30px;
  right: 15px;
  top: unset;
  height: 20px;
  color: gray;
  font-size: 35px;
}

.toast-success {
  background-image: url("./assets/img/toaster/success.svg");
  background-color: #FFFFFF;
}

.toast-success-inbox {
  background-color: #3D4244;
  color: #FFFF;
  width: 80px !important;
  font-size: 12px;
}

.toast-error-inbox {
  background-color: #a1a5a7;
  color: red;
  width: 100px !important;
}

.toast-info {
  background-image: url("./assets/img/toaster/info.svg");
  background-color: #FFFFFF;
}

.toast-error {
  background-image: url("./assets/img/toaster/error.svg");
  background-color: #FFFFFF;
}

.toast-warning {
  background-image: url("./assets/img/toaster/warning.svg");
  background-color: #FFFFFF;
}

.ngx-toastr {
  pointer-events: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3), 0px 12px 24px rgba(0, 0, 0, 0.2) !important;
  border-radius: 4px !important;
  width: 420px !important;
  background-position: 23px 15px !important;
  padding: 15px 15px 15px 58px !important;
}
.ngx-toastr::before {
  content: "";
  width: 10px;
  height: 100%;
  background: #DDF7E0;
  position: absolute;
  top: 0px;
  left: 0px;
}
.ngx-toastr .toast-close-button {
  color: #000000;
}
@media all and (max-width: 429px) and (min-width: 415px) {
  .ngx-toastr {
    width: 398px !important;
  }
}
@media all and (max-width: 414px) and (min-width: 392px) {
  .ngx-toastr {
    width: 383px !important;
  }
}
@media all and (max-width: 391px) and (min-width: 377px) {
  .ngx-toastr {
    width: 360px !important;
  }
}
@media all and (max-width: 376px) and (min-width: 362px) {
  .ngx-toastr {
    width: 345px !important;
  }
}
@media all and (max-width: 361px) {
  .ngx-toastr {
    width: 330px !important;
  }
}

.toast-title {
  font-family: Biotif;
  font-weight: 600;
  font-size: 14px;
  color: #000000;
}

.toast-message {
  margin-top: 7px;
  word-wrap: break-word;
  font-family: Biotif;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}

.toast-success-custom::before {
  content: "";
  background: #DDF7E0;
}

.toast-info-custom::before {
  content: "";
  background: #EEE8FF;
}

.toast-error-custom::before {
  content: "";
  background: #FFDCE4;
}

.toast-warning-custom::before {
  content: "";
  background: #FFF3CC;
}

.toast-bottom-right .InboxToaster {
  position: relative;
  right: 170px !important;
  width: 100px !important;
  background-image: none !important;
}
.toast-bottom-right .InboxToaster .toast-message {
  color: #ffffff !important;
  text-align: center !important;
  font-size: 12px !important;
  margin-top: 0px;
}

.ngx-toastr-inbox {
  pointer-events: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3), 0px 12px 24px rgba(0, 0, 0, 0.2) !important;
  border-radius: 4px !important;
  width: 420px !important;
  background-position: 23px 15px !important;
  padding: 15px 15px 15px 15px !important;
  margin-bottom: 50px;
}
.ngx-toastr-inbox .toast-close-button {
  display: none;
}
@media all and (max-width: 429px) and (min-width: 415px) {
  .ngx-toastr-inbox {
    width: 398px !important;
  }
}
@media all and (max-width: 414px) and (min-width: 392px) {
  .ngx-toastr-inbox {
    width: 383px !important;
  }
}
@media all and (max-width: 391px) and (min-width: 377px) {
  .ngx-toastr-inbox {
    width: 360px !important;
  }
}
@media all and (max-width: 376px) and (min-width: 362px) {
  .ngx-toastr-inbox {
    width: 345px !important;
  }
}
@media all and (max-width: 361px) {
  .ngx-toastr-inbox {
    width: 330px !important;
  }
}