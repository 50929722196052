@font-face {
    font-family: 'Biotif Book';
    src: url('../fonts/Biotif-Book.woff2') format('woff2'),
        url('../fonts/Biotif-Book.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Biotif Extra';
    src: url('../fonts/Biotif-ExtraBold.woff2') format('woff2'),
        url('../fonts/Biotif-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Biotif';
    src: url('../fonts/Biotif-Black.woff2') format('woff2'),
        url('../fonts/Biotif-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Biotif';
    src: url('../fonts/Biotif-Bold.woff2') format('woff2'),
        url('../fonts/Biotif-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Biotif';
    src: url('../fonts/Biotif-Light.woff2') format('woff2'),
        url('../fonts/Biotif-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Biotif';
    src: url('../fonts/Biotif-Medium.woff2') format('woff2'),
        url('../fonts/Biotif-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Biotif';
    src: url('../fonts/Biotif-Regular.woff2') format('woff2'),
        url('../fonts/Biotif-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Biotif';
    src: url('../fonts/Biotif-SemiBold.woff2') format('woff2'),
        url('../fonts/Biotif-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

body,
html {
    -webkit-text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

body {
    font-family: 'Biotif';
    font-weight: 600;
    color: #1C1C1C;
    line-height: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}

ol,
ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

a {
    text-decoration: none;
}

a:focus,
a:hover {
    text-decoration: none;
}

h1 {}

h2 {
    font-size: 24px;
    font-weight: 600;
}

h3 {
    font-size: 20px;
    font-weight: 600;
}

h4 {
    font-size: 18px;
}

h5 {
    font-size: 16px;
    font-weight: 600;
}

h6 {
    font-size: 14px;
    font-weight: 600;
}

p {
    font-size: 14px;
    line-break: anywhere;
}

a {
    color: inherit;
}

a:hover {
    color: inherit;
}

.pad {
    padding: 100px 0;
}

.bg_image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.custom_button {
    padding: 12px 20px;
    font-size: 16px;
    transition: .2s ease all;
    border: none;
    display: inline-block;
    min-width: 140px;
    text-align: center;
    text-transform: capitalize;
    border-radius: 4px;
    transition: .2s ease all;
}

.custom_button.yellow {
    background: #FFC93B;
    color: #4F4F4F;
    border: 1px solid #FFC93B;
}

.custom_button.yellow:hover {
    background-color: #f5bb24;
    border-color: #f5bb24;
}

.custom_button.green {
    background: #37D584;
    color: #1C1C1C;
    border: 1px solid #37D584;
}

.custom_button.green:hover {
    background-color: #20bb6c;
    border-color: #37D584;
}

.custom_button.line {
    background-color: #fff;
    border: 1px solid #5D5D5D;
}

.custom_button.line:hover {
    background-color: #5D5D5D;
    border-color: #5D5D5D;
    color: #fff;
}

.custom_button.gray {
    background: rgb(128, 128, 128);
    color: #1C1C1C;
    border: 1px solid gray;
}

.reportbackbtn {
    position: absolute;
    right: 48px;
}

.reportlist {
    margin-top: -74px;
}

input:focus,
select:focus,
textarea:focus {
    outline: 0;
}

img {
    /* max-width: 100%; */
}

header {
    position: sticky;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    padding: 15px 0;
    background-color: #fff;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .3)
}

header.fixed {
    transition: .2s ease all;
    z-index: 999;

}

.teamdetails .teamslider {
    display: none;
}

.teamdetails .teamsample {
    display: none;
}

.teamdetails .teamslider {
    display: block;
}

@media only screen and (min-width: 320px) and (max-width: 425px) {
    .custom_button {
        min-width: max-content;
    }
}

 .mention-menu {
  min-width: 15em !important;
 
}
.mention-item {
  line-height: 2.5em !important;
}
.mention-item .label {
  grid-column: 1;
  grid-row: 1;
  width: 35px !important;
  height: 35px !important;
  border-radius: 50% !important;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-justify-content: center;
  background: #9EEEFD;
  z-index: 10;
}

  .mention-item .label .lblimage {
    font-family: 'Biotif';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    color: #1C1C1C;
    padding: 2px 0px 0px 1px;
  }

.customemoji > .emoji-mart > .emoji-mart-bar {
  display: none !important;
}

.customemoji > .emoji-mart {
  width: 400px !important;
  font-family: 'Biotif' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
}
   .emoji-mart-search input {
    font-family: 'Biotif' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
  }
.emoji-mart-category .emoji-mart-emoji span {
  cursor: pointer !important;
}
.example-radio-group {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  align-items: flex-start;
}

.example-radio-button {
  margin: 5px;
  font-size: 14px;
  font-weight: 400;
}

span.red > ul {
  list-style-type: square !important;
  padding-left:12px;
}
.search-table {
  font-family: Biotif;
  font-style: normal;
  width: 25%;
  box-sizing: border-box;
  border: 0px;
  font-size: 14px;
  background-color: white;
  background-position: center;
  padding: 5px 7px 5px 30px;
  background: url('../../assets/img/header/search-icon.svg');
  background-repeat: no-repeat !important;
  background-position-x: 5px;
  background-position-y: 5px;
}
 .search-table:focus {
  border: 1px solid #ccc;
  border-radius: 4px;
}
.div-search {
  margin-left: 35px;
  margin-top: 5px
}
@media screen and (max-width: 500px) {
  .div-search {
    margin-left: 10px;
  }

  .nav-item {
    width: 25% !important;
  }

  .afterNav {
    width: 1% !important;
  }

  .nav-pills {
    flex-wrap: wrap !important;
    width:95% !important;
  }

  .nav-link-text {
    text-wrap: balance !important;
  }
}
