@import url(style.css);

/*General CSS*/

.main {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
}

#mobnav_button_icon {
  width: 24px;
  height: 20px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

  #mobnav_button_icon span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #9d9d9d;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }

    #mobnav_button_icon span:nth-child(1) {
      top: 0px;
    }

    #mobnav_button_icon span:nth-child(2),
    #mobnav_button_icon span:nth-child(3) {
      top: 8px;
    }

    #mobnav_button_icon span:nth-child(4) {
      top: 16px;
    }

  #mobnav_button_icon.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
  }

  #mobnav_button_icon.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    background: #fff;
  }

#nav-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  background: #fff;
}

#nav-icon.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
  background: #fff;
}


/*General CSS*/


/*Webapp CSS*/
.space-icon {
  border-bottom: 1px solid #1C1C1C;
}

.sidebar {
  background-color: #F8F8F8;
  width: 200px;
  position: fixed;
  height: 100vh;
  transition: .3s ease all;
  margin-left: -136px;
  z-index: 99999;
  border: 1px solid #EBEBEB;
}

  .sidebar.open {
    margin-left: 0;
    transition: .3s ease all;
    z-index: 99999;
  }

.stopbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
}

  .stopbar .logo img {
    width: 40px;
  }

.main > .data {
  margin-left: 59px;
  transition: .2s ease all;
  width: 100%;
  overflow: hidden;
}

  .main > .data.open {
    transition: .2s ease all;
    margin-left: 195px;
  }

.header {
  border-bottom: 1px solid #EBEBEB;
  padding: 20px 40px 20px 32px;
  display: grid;
  gap: 0px 20px;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 39px;
  align-items: center;
}

@media all and (max-width: 428px) {
  .header {
    gap: 0px 5px;
  }
}

.header .head {
  font-weight: 900;
}

.header .sides {
  display: flex;
  align-items: center;
  font-family: 'Biotif';
  font-style: normal;
  font-size: 15px;
  line-height: 18px;
}

  .header .sides .user_name {
    font-weight: 600;
    font-size: 16px;
  }

  .header .sides .user_image {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    overflow: hidden;
    margin-left: 10px;
    background: #FFFFFF;
    border: 1px solid #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

@media all and (max-width: 428px) {
  .header .sides .user_image {
    margin-left: 5px;
  }
}

.header .sides .user_image .user_logo {
  margin-top: 3px;
  font-weight: 400;
  line-height: 15px;
}

.sidebar_in {
  height: 100vh;
}

.sidebar_menu {
  padding: 0px 0 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 92%;
}

  .sidebar_menu .bottom_menu {
    position: relative;
    padding-top: 20px;
  }

    .sidebar_menu .bottom_menu:before {
      content: '';
      position: absolute;
      width: 75%;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      /* background-color: #fff; */
      margin: 0 auto;
    }

  .sidebar_menu li .text {
    padding-top: 3px;
    color: #4f4f4f;
    font-weight: 450;
    font-size: 15px;
  }
  .sidebar_menu li .simple-text {
    padding-top: 3px;
    color: #9D9D9D;
    margin-left:-15px;
  }
  .sidebar_menu li .hr-line {
    width: 25px;
    height:2px;
  }
  .sidebar_menu li {
    padding: 5px 20px 5px 30px;
    position: relative;
    margin-bottom: 20px;
  }

    .sidebar_menu li.active:before {
        content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 3px;
      height: 100%;
      background-color: #E9F2FF;

    }


    .sidebar_menu li a {
      color: #9D9D9D;
      font-weight: 400;
      font-size: 15px;
      display: block;
      transition: .2s ease all;
      display: flex;
      align-items: flex-start;
    }

    .sidebar_menu li.active a {
      color: #fff;
    }

    .sidebar_menu li a:hover {
      color: #fff;
      transition: .2s ease all;
    }

    .sidebar_menu li span {
      display: inline-block;
    }

.sidebar.open .sidebar_menu li span.icon {
  margin-right: 20px;
  height: 20px;
  position: relative;
  right: inherit;
  transition: .2s ease all;
}

.sidebar .sidebar_menu li span.icon {
  position: absolute;
  right: 20px;
  transition: .3s ease all;
}

.sidebar_menu li span.icon svg {
  height: 19px;
}

.sidebar_menu li a svg path {
  transition: .2s ease all;
  fill: #9d9d9d;
}

.sidebar_menu li a:hover svg path,
.sidebar_menu li.active svg path {
  fill: #fff;
}

.head div.dropdown {
  position: relative;
  min-width: 180px;
  font-family: 'Biotif';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #1C1C1C;
}

  .head div.dropdown span.arrow {
    position: absolute;
    top: 7px;
    right: -15px;
    transition: .2s ease all;
  }

div.dropdown.open span.arrow {
  transform: rotate(180deg);
}

div.dropdown > div.caption {
  background-color: #fff;
  padding: 8px 10px;
  cursor: pointer;
}

div.dropdown > div.caption {
  display: inline-block;
}

div.dropdown > div.list {
  position: absolute;
  background-color: #fff;
  display: none;
  z-index: 2;
  overflow: auto;
  width: max-content;
  box-shadow: 8px 8px 8px rgb(0 0 0 / 5%);
  z-index: 2;
  overflow: auto;
  height: auto;
}

  div.dropdown > div.list > div.item {
    padding-top: 12px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    cursor: pointer;
    border-bottom: 1px solid #EBEBEB;
  }

    div.dropdown > div.list > div.item.selected {
      color: #FFB200;
    }

    div.dropdown > div.caption:hover,
    div.dropdown > div.list > div.item:hover {
      color: #FFB200;
      transition: .2s ease all;
    }

div.dropdown.open > div.caption {
  border-radius: 3px 3px 0 0;
  /*    border-bottom: solid 1px #EBEBEB;*/
}

div.dropdown.open > div.list {
  display: block;
}

.data .data_in {
  padding: 40px 40px;
}

#search_modal .modal-dialog {
  width: 100%;
  max-width: inherit;
  height: 100%;
  margin: 0;
  padding: 40px;
}

  #search_modal .modal-dialog .modal-content {
    height: 100%;
  }

#search_modal .modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.modal .btn-close {
  position: absolute;
  top: 20px;
  right: 20px;
}

.full-screen-search {
  position: relative;
  width: 60%;
}

.sr-only {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  overflow: hidden;
  padding: 0;
  border: 0;
  white-space: nowrap;
}

.full-screen-search input.full-screen-search__input {
  background: 0 0;
  width: 100%;
  padding: 30px 30px 30px 20px;
  border: none;
  border-bottom: 2px solid #d3d3d4;
  font-size: 40px;
}

.full-screen-search button.full-screen-search__btn {
  position: absolute;
  top: 23px;
  right: 10px;
  width: 60px;
  height: 60px;
  background-color: #010101;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

  .full-screen-search button.full-screen-search__btn svg path {
    fill: #fff;
  }

.dr_leftcolm {
  padding-right: 20px;
}

.tasks {
  margin-bottom: 50px;
}

  .tasks .heading {
    margin-bottom: 20px;
    position: relative;
  }

    .tasks .heading .sa {
      position: absolute;
      top: 5px;
      right: 0;
    }

      .tasks .heading .sa a {
        color: #5C5C5C;
        font-size: 16px;
        font-weight: 600;
        transition: .2s ease all;
      }

        .tasks .heading .sa a:hover {
          color: #FFB200;
        }

    .tasks .heading h2 span {
      display: inline-block;
      margin-left: 5px;
    }

.document {
  margin-bottom: 20px;
}

  .document .doc_in {
    position: relative;
    background: #FAFAFA;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 25px;
    overflow: hidden;
  }

    .document .doc_in:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 6px;
      height: 100%;
      background-color: #FFB200;
    }

    .document .doc_in h4,
    .document .doc_in p {
      width: 90%;
      margin-bottom: 0;
    }

    .document .doc_in h4 {
      color: #1C1C1C;
      font-weight: 600;
    }

    .document .doc_in p {
      color: #5D5D5D;
      margin-top: 10px;
      font-weight: 400;
    }

      .document .doc_in p span {
        font-weight: 900;
        margin-right: 8px;
      }

    .document .doc_in .arrow {
      position: absolute;
      top: 40px;
      right: 20px;
    }

.report {
  margin-bottom: 20px;
}

  .report .doc_in {
    background: #FAFAFA;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 20px 25px;
    position: relative;
  }

    .report .doc_in .arrow {
      position: absolute;
      top: 22px;
      right: 20px;
    }

    .report .doc_in h6 {
      color: #5D5D5D;
    }

.grey_boxes {
  background: #FAFAFA;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 30px 50px;
}

.gb_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

  .gb_heading .gbh_right .dropdown {
    display: inline-block;
  }

    .gb_heading .gbh_right .dropdown:last-child {
      margin-left: 20px;
    }

.gbh_right .dropdown .arrow {
  position: absolute;
  top: 12px;
  right: 0;
}

.gbh_right div.dropdown > div.caption {
  background-color: #fafafa;
  font-size: 16px;
  padding-right: 30px;
}

.gbh_right div.dropdown > div.list > div.item {
  font-size: 16px;
}

.gbi_accordian > ul {
  list-style: none;
  padding: 0;
}

  .gbi_accordian > ul > li {
    margin-bottom: 10px;
  }

    .gbi_accordian > ul > li:last-child {
      margin-bottom: 0;
    }

.gbi_accordian ul li a.toggle {
  width: 100%;
  display: block;
  padding: 10px 10px;
  transition: background 0.3s ease;
  position: relative;
  text-decoration: none;
}

  .gbi_accordian ul li a.toggle h5 {
    width: 95%;
    margin-left: auto;
  }

  .gbi_accordian ul li a.toggle > span {
    position: absolute;
    top: 12px;
    left: 0px;
    transition: .3s ease all;
  }

.gbi_accordian ul li.active > a.toggle span {
  transform: rotate(90deg);
}

.gbi_accordian ul .inner {
  padding: 5px 10px 5px 40px;
  overflow: hidden;
  display: none;
  position: relative;
}

  .gbi_accordian ul .inner .dot {
    position: absolute;
    top: 4px;
    left: 12px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #1BD074;
  }

  .gbi_accordian ul .inner h6 {
    color: #5D5D5D;
    font-weight: 600;
  }

  .gbi_accordian ul .inner .price {
    color: #1C1C1C;
    font-weight: 900;
    margin-top: 5px;
  }

.outstanding_cols {
  text-align: center;
}

.os_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #EBEBEB;
  margin: 0 auto;
}

.os_text {
  margin-top: 10px;
}

  .os_text .ostf {
    color: #1C1C1C;
    font-size: 18px;
    font-weight: 600;
  }

  .os_text .osts {
    color: #606060;
    font-size: 16px;
    font-weight: 600;
    margin: 5px 0 8px 0;
  }

  .os_text .ostt {
    color: #1C1C1C;
    font-size: 18px;
    font-weight: 900;
  }

.chart_div {
  position: relative;
}

.charts_slider {
  position: relative;
}

  .charts_slider .cs_in {
    position: relative;
  }

.cs_in .cs_details {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cs_details .csd_in {
  text-align: center;
}

.csd_in .month {
  margin-bottom: 5px;
}

.csd_in .year {
  color: #1C1C1C;
  font-size: 20px;
  font-weight: 900;
  margin-bottom: 10px;
}

.csdi_text p {
  color: #5D5D5D;
  font-size: 14px;
  margin-bottom: 8px;
}

.cs_in .profitpercent {
  position: absolute;
  top: 20px;
  left: 0px;
}

.profitpercent .num {
  color: #1BD074;
  font-size: 14px;
  font-weight: 900;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 20px;
}

  .profitpercent .num span {
    color: #9D9D9D;
    font-size: 20px;
    font-weight: 600;
    margin-left: 15px;
  }

  .profitpercent .num img {
    position: absolute;
    top: 5px;
    left: 0;
    width: 12px;
    margin-right: 5px;
  }

.cs_arrows .arrow_left {
  position: absolute;
  top: 45%;
  left: 27%;
  z-index: 9;
  cursor: pointer;
}

.cs_arrows .arrow_right {
  position: absolute;
  top: 45%;
  right: 27%;
  z-index: 9;
  cursor: pointer;
}

.modal {
  z-index: 9999999;
}

.header .head .mobnav_icon {
  display: none;
}

.reports .reports_tab .tab {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto 1fr 35px 35px 35px 15px 35px 35px;
  align-items: center;
  padding-left: 40px;
}

  .reports .reports_tab .tab .tablinks {
    margin-right: 15px;
    padding-right: 15px;
    color: #9D9D9D;
    font-size: 20px;
    cursor: pointer;
    font-weight: 600;
    transition: .2s ease all;
    border-right: 2px solid #1c1c1c33;
    width: max-content;
  }

  .reports .reports_tab .tab .active {
    color: #1C1C1C;
    /* font-weight: 800; */
    border-right: 2px solid #1C1C1C;
  }

  .reports .reports_tab .tab .active-last {
    color: #1C1C1C;
    font-weight: 800;
  }

.reports_in .reports_box {
  background-color: #FAFAFA;
  border-radius: 10px;
  padding: 30px 25px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.05);
  margin-top: 30px;
}

.reports_box .rb_in {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
}

.rb_in .icon img {
  width: 50px;
}

.rb_in .data {
  margin-left: 20px;
  padding-right: 40px;
}

  .rb_in .data h3 {
    margin-bottom: 10px;
  }

  .rb_in .data p {
    font-weight: 500;
  }

.report_history .rh_outer {
  background: #FAFAFA;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 15px;
  /* margin-top: 30px; */
  /* border: 1px solid #F4F4F4; */
  /* padding: 15px 0; */
}

.s-hidden {
  visibility: hidden;
  padding-right: 10px;
}

.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border: none;
}

.styledSelect {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  padding: 0 10px;
}

  .styledSelect:after {
    content: "";
    width: 0;
    height: 0;
    background-image: url(../img/down-arrow.svg);
    background-repeat: no-repeat;
    background-size: contain;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 7px;
    right: 0px;
  }


/*
.styledSelect:active, .styledSelect.active {
    background-color:#eee;
}
*/

.options {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 999;
  margin: 0 0;
  padding: 5px 0;
  list-style: none;
  background-color: white;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  margin-top: 5px;
}

  .options li {
    padding: 5px 10px;
    margin: 0 0;
  }

    .options li:hover {
      background-color: #39f;
      color: white;
    }

.rh_in table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

  .rh_in table th {
    color: #9D9D9D;
    font-size: 16px;
    font-weight: 600;
  }

  .rh_in table tr th,
  .rh_in table tr td {
    width: 25%;
    padding: 18px 20px;
    text-align: left;
  }

  .rh_in table.outstanding tr th,
  .rh_in table.outstanding tr td {
    width: auto;
  }

  .rh_in table tr td {
    color: #1C1C1C;
    font-weight: 500;
    font-size: 16px;
    /*    padding-left: 1em;*/
  }

    .rh_in table tr td:nth-child(1) {
      font-weight: 600;
    }

      .rh_in table tr td:nth-child(1) span {
        margin-right: 10px;
      }

        .rh_in table tr td:nth-child(1) span img {
          width: 20px;
        }

  .rh_in table .comments .com p {
    margin-bottom: 6px;
    font-size: 16px;
    font-weight: normal;
    line-height: 22px;
  }

    .rh_in table .comments .com p.incomplete {
      color: #9D9D9D;
      font-weight: normal;
      cursor: pointer;
    }

    .rh_in table .comments .com p span {
      color: #1C1C1C;
      font-weight: 700;
      margin-right: 8px;
    }

    .rh_in table .comments .com p a {
      color: #157AF3;
      font-weight: 600;
      font-size: 14px;
      margin-left: 5px;
    }

.pagenum {
  text-align: center;
  margin-top: 30px;
  position: relative;
}

  .pagenum ul {
    display: flex;
    align-items: center;
    justify-content: center;
  }

    .pagenum ul li {
      background: #FAFAFA;
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      padding: 5px;
      margin: 0 5px;
    }

      .pagenum ul li:nth-child(1),
      .pagenum ul li:nth-child(3) {
        padding: 8px 5px;
      }

      .pagenum ul li a {
        padding: 12px 15px;
        display: inline-flex;
        font-size: 16px;
        color: #9D9D9D;
        transition: .2s ease all;
      }

        .pagenum ul li a.active,
        .pagenum ul li a:hover {
          color: #1C1C1C;
        }

  .pagenum .pg_buttons {
    position: absolute;
    top: 5px;
    right: 30px;
  }

    .pagenum .pg_buttons .custom_button {
      min-width: inherit;
      margin: 0 5px;
    }


/*Report Side Panel*/

.sidenav {
  height: 100%;
  width: 465px;
  position: fixed;
  top: 0;
  right: -465px;
  background-color: #111;
  transition: 0.2s;
  overflow: hidden;
  background: #FAFAFA;
  box-shadow: -1px 0px 4px rgba(0, 0, 0, 0.1);
}

.sidenav-show::after {
  width: 100vw;
  height: 100vh;
  background: #00000096;
  position: fixed;
  top: 0px;
  left: 0px;
  content: '';
  z-index: -1;
}

@media all and (max-width: 465px) {
  .sidenav {
    width: 100%;
    min-width: 100%;
    right: -100%;
  }
}

.sidenav-show {
  right: 0;
  transition: 0.5s;
  z-index: 9999;
}

.sidenav .sidenav_in {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fafafa;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100vh;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.reportg {
  padding: 60px 30px;
  padding-top: 20px !important;
}

  .reportg .heading {
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    color: #1C1C1C;
    font-family: 'Biotif';
  }

    .reportg .heading:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #393939;
    }

.report_period {
  padding: 30px 0;
}

.reportg h4 {
  font-weight: 600;
  margin-bottom: 20px;
}

.report_period #select {
  padding: 12px 10px 10px 10px;
  width: 90%;
  border: 0.5px solid #E5E5E5;
}

.report_period .custom_drop .select {
  width: 90%;
  padding: 10px 5px;
  background: #fff;
}

.report_period .custom_drop .styledSelect {
  padding: 12px 10px 10px 10px;
  border: 0.5px solid #E5E5E5;
}

  .report_period .custom_drop .styledSelect:after {
    top: 15px;
    right: 10px;
  }

.report_period .custom_drop .options {
  margin-top: 0;
  padding: 5px;
}

.custom_range .tab_head ul {
  display: flex;
  align-items: center;
}

  .custom_range .tab_head ul li {
    color: #9D9D9D;
    font-size: 18px;
    transition: .2s ease all;
    font-weight: 500;
    margin-right: 15px;
    position: relative;
    padding-bottom: 10px;
    cursor: pointer;
  }

    .custom_range .tab_head ul li.active,
    .custom_range .tab_head ul li:hover {
      color: #393939;
    }

      .custom_range .tab_head ul li.active:after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 1px;
        left: 0;
        background: #393939;
      }

.custom_range .tab_data {
  margin-top: 20px;
}

.compare_period {
  margin-top: 40px;
}

  .compare_period form {
    text-align: left;
    padding-top: 20px;
  }

.value-button {
  display: inline-block;
  margin: 0px;
  width: 40px;
  height: 35px;
  text-align: center;
  vertical-align: middle;
  padding: 7px 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 22px;
}

  .value-button:hover {
    cursor: pointer;
  }

form #decrease {
  margin-right: -4px;
  border-radius: 8px 0 0 8px;
}

form #increase {
  margin-left: -4px;
  border-radius: 0 8px 8px 0;
}

form #input-wrap {
  margin: 0px;
  padding: 0px;
}

input#number {
  text-align: center;
  border: none;
  margin: 0px;
  width: 60px;
  height: 35px;
  background: #FFFFFF;
  border: 0.5px solid #E5E5E5;
  font-size: 16px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.sidenav_in .reportg_btn {
  padding: 40px 0;
}

.reportg_btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

  .reportg_btn a {
    padding: 10px 20px;
    font-weight: 500;
    font-size: 16px;
    transition: .2s ease all;
    color: #5D5D5D;
  }

    .reportg_btn a.run {
      background: #FFC93B;
      border-radius: 4px;
      color: #1C1C1C;
    }


/*Report Side Panel*/

.ucr_table.rh_in table thead {
  margin-bottom: 20px;
}

.ucr_table.rh_in table {
  table-layout: fixed;
}

  .ucr_table.rh_in table tr th {
    padding-left: 5px;
    width: auto;
    padding-bottom: 30px;
  }


  /*
.ucr_table.rh_in table tr th:nth-child(5), .ucr_table.rh_in table tr td:nth-child(5) {
    width: 28%;
}
*/

  .ucr_table.rh_in table tr td {
    vertical-align: top;
  }

    .ucr_table.rh_in table tr td b {
      font-weight: 800;
    }

.data_modal .dm_head {
  padding: 20px 20px;
}

.data_modal .dm_content {
  padding: 20px 30px;
}

.data_modal .modal-body h3 {
  text-align: center;
  width: 100%;
}

.data_modal .dm_content p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.data_modal .dm_content textarea {
  width: 100%;
  padding: 15px;
}

.data_modal .dm_content .modal_btns {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.modal_btns .custom_button {
  margin: 0 10px;
}

.data_modal .dm_content form {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.dm_content .form-group {
  margin-bottom: 20px;
}

  .dm_content .form-group label {
    margin-bottom: 8px;
    display: block;
    text-align: center;
    font-size: 14px;
  }

  .dm_content .form-group input:not([disabled]):not([type="submit"]),
  .dm_content .form-group select,
  .dm_content .form-group textarea {
    border: 0.5px solid #E5E5E5;
    padding: 10px;
    background: #fff;
    font-size: 14px;
    width: 100%;
    text-align: center;
  }


/* The switch - the box around the slider */

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  /* height: 5px; */
}


  /* Hide default HTML checkbox */

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }


/* The slider */

.slider {
  position: absolute;
  cursor: pointer;
  top: -2px;
  left: 0;
  right: 0;
  bottom: 3px;
  background-color: #D1D1D1;
  -webkit-transition: .4s;
  transition: .4s;
}

  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 0px;
    bottom: -6px;
    background-color: #1BD074;
    -webkit-transition: .4s;
    transition: .4s;
  }

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}


/* Rounded sliders */

.slider {
  border-radius: 34px;
}

  .slider:before {
    border-radius: 50%;
  }

.report_name input {
  padding: 12px 10px 10px 10px;
  width: 90%;
  border: 0.5px solid #E5E5E5;
}

.compare_period h4 {
  margin-bottom: 0;
}

.bank_account {
  margin-top: 40px;
}

.ba_in table tr th {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #9D9D9D;
}

.ba_in table tr th,
.ba_in table tr td {
  padding: 5px;
}

.ba_in table tr td {
  font-weight: 450;
  font-size: 14px;
  line-height: 24px;
  color: #1C1C1C;
}

.reportg .advance_settings {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #D6D6D6;
}

  .reportg .advance_settings a {
    display: flex;
    align-items: end;
    justify-content: center;
  }

.advance_settings .ads_name {
  margin-left: 5px;
  color: #157AF3;
}

.rh_in table.outstanding {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

.rh_in table td,
.rh_in table th {
  white-space: nowrap;
  text-align: left;
  padding: 10px 15px 10px 0;
  /*    font-size: 12px;*/
}

.rh_in table.outstanding tr th,
.rh_in table.outstanding tr td {
  width: auto;
}


/*
.rh_in table.outstanding tr th:last-child, .rh_in table.outstanding tr td:last-child {
    width: 10%;
}
*/

.supplier {
  display: flex;
  align-items: flex-start;
}


  /* --------- */

  .supplier .sup_img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .supplier .sup_img_box {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 5px;
    display: inline-flex;
    background-color: #9EEEFD;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .supplier .sup_img span {
    user-select: none;
    font-weight: bold;
    font-size: 18px;
  }

  .supplier .sup_img_box_1 {
    background-color: #ee99a3;
    color: #4d1219;
  }

  .supplier .sup_img_box_2 {
    background-color: #9eeefd;
    color: #154e58;
  }

  .supplier .sup_img_box_3 {
    background-color: #aeadf3;
    color: #202051;
  }

  .supplier .sup_img_box_4 {
    background-color: #a8eed5;
    color: #1c4d3c;
  }

  .supplier .sup_img_box_5 {
    background-color: #fac180;
    color: #582e00;
  }

  .supplier .sup_img_box_6 {
    background-color: #80c19d;
    color: #0a2e15;
  }

.table_custom_row {
  width: 100% !important;
}

input#reportName {
  width: 300px;
}


/* ------ */

.supplier .sup_img img {
  width: 100%;
  height: 100%;
}

.supplier .sup_det {
  margin-left: 10px;
  text-align: left;
}

  .supplier .sup_det .sd_email {
    color: #5D5D5D;
    font-size: 15px;
    font-weight: 400;
    margin: 0px 0;
  }

  .supplier .sup_det .sd_link {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
  }

.sup_det .sd_link.collapsed img {
  transform: rotate(-180deg);
}

.sup_det .sd_link img {
  width: 10px !important;
  transition: .2s ease all;
  transform: rotate(0deg);
}

table.outstanding tr td .remove {
  opacity: 0;
  transition: .2s ease all;
}

table.outstanding tr:hover td .remove {
  opacity: 1;
}


/*Budget*/

.reports .sales_data {
  background: #FAFAFA;
  box-shadow: 4px 4px 8px rgb(0 0 0 / 5%);
  border-radius: 10px;
  padding: 30px 80px;
  margin-top: 30px;
}

  .reports .sales_data .profit_summary {
    overflow-x: auto;
  }

table.profit {
  border-spacing: 0;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

  table.profit table {
    border-spacing: 0;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
  }

    table.profit table tr td:nth-child(1) {
      text-align: center;
    }

  table.profit tr th {
    height: 60px;
  }

  table.profit tr {
    border-bottom: 1px solid #EBEBEB;
  }

    table.profit tr td {
      padding: 15px 20px 15px 0;
      color: #9D9D9D;
      font-size: 15px;
      font-weight: 400;
    }

  table.profit td .element {
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    color: #1C1C1C;
    font-weight: 600;
  }

    table.profit td .element:before {
      content: '';
      position: absolute;
      width: 5px;
      height: 14px;
      left: 0;
      top: 1px;
      border-radius: 30px;
    }

    table.profit td .element.red:before {
      background-color: #F44336;
    }

    table.profit td .element.green:before {
      background-color: #1BD074;
    }

    table.profit td .element.blue:before {
      background-color: #40D1FF;
    }

    table.profit td .element .arrow {
      margin-left: 5px;
    }

      table.profit td .element .arrow img {
        width: 10px;
        transition: .2s ease all;
        transform: rotate(180deg);
      }

    table.profit td .element.collapsed .arrow img {
      width: 10px;
      transform: rotate(0deg);
    }


/*Budget*/

.chart-container {
  position: relative;
  margin: auto;
  height: 500px;
  width: 80vw;
}

.reports_data .user_search {
  background: #FAFAFA;
  /*border: 1px solid #D6D6D6;*/
  border-radius: 10px;
  /*padding: 15px 30px; */
  margin: 20px 0;
}

.user_search .usersearch_in {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.usersearch_in .usearch {
  width: 50%;
  position: relative;
}

  .usersearch_in .usearch input {
    border: none;
    padding: 10px 15px 10px 40px;
    background-color: transparent;
    font-weight: 600;
    font-size: 16px;
    width: 100%;
  }

    .usersearch_in .usearch input::placeholder {
      color: #9D9D9D;
    }

  .usersearch_in .usearch .icon {
    position: absolute;
    top: 8px;
    left: 5px;
  }

.usersearch_in .adduser .adu {
  position: relative;
  padding-left: 20px;
  color: #157AF3;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

  .usersearch_in .adduser .adu img {
    width: 12px;
    position: absolute;
    top: 0;
    left: 0;
  }

table .selection {
  cursor: pointer;
}

  table .selection span {
    transition: .2s ease all;
    display: inline-block;
  }

  table .selection.active span {
    transform: rotate(180deg);
  }

.rh_in .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

  .rh_in .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 2px;
    bottom: 2px;
    background-color: #F44336;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }

.rh_in input:checked + .slider:before {
  background-color: #1BD074;
}

.rh_in input:checked + .slider {
  background-color: #ccc;
}

  .rh_in input:checked + .slider:before {
    -webkit-transform: translateX(18x);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }

.rh_in .action_btns {
  display: flex;
  align-items: center;
}

  .rh_in .action_btns a {
    margin: 0 5px;
  }

.action_btns .sq_btn {
  width: 25px;
  height: 25px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: .2s ease all;
}

  /* .action_btns .sq_btn img {
    height: 12px;
} */

  .action_btns .sq_btn.edit {
    background-color: transparent;
  }

    .action_btns .sq_btn.edit:hover {
      background-color: transparent;
    }
/*
.action_btns .sq_btn.delete {
    background-color: #F44336;
}

.action_btns .sq_btn.delete:hover {
    background-color: #d03024;
} */

.cpy_in {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

  .cpy_in input[type=text] {
    width: 40px;
    margin-left: 15px;
    padding: 5px 10px;
    font-size: 14px;
    background: #FFFFFF;
    border: 0.5px solid #E5E5E5;
  }

.addition {
  background: #FAFAFA;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 15px 30px;
  display: inline-flex;
  margin-top: 20px;
}

  .addition .adduser .adu {
    position: relative;
    padding-left: 20px;
    color: #157AF3;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
  }

    .addition .adduser .adu img {
      width: 12px;
      position: absolute;
      top: 0;
      left: 0;
    }

table#settingtable tr th,
table#settingtable tr td {
  width: inherit;
}

table.tablewithdropdowns td select {
  border: none;
  background: transparent;
}

table.tablewithdropdowns td input[type=text] {
  border: none;
  background: transparent;
}

table.tablewithdropdowns td input.bold {
  font-weight: 600;
}

table tr th.centertable {
  text-align: center;
}

table tr td.centertable .action_btns {
  justify-content: center;
}

#ui-datepicker-div {
  max-width: 17em;
}

table tr.grey td input,
table tr.grey td select,
table tr.grey td {
  color: #9D9D9D !important;
}

.report_history .rh_outer .rh_in {
  /* overflow-x: auto; */
  width: 100%;
}


/*Webapp CSS*/


/*Responsive CSS*/

@media(max-width:1700px) {
}

@media(max-width:1500px) {
  .rh_in table tr td {
    font-size: 14px;
  }
}

@media(max-width:1400px) {
}

@media(max-width:1200px) {
  .outstanding_cols {
    margin: 20px 0;
  }

  .chart-container {
    height: 400px;
  }

  .reports .sales_data {
    padding: 30px 40px;
  }

  table.profit,
  table.profit table {
    table-layout: auto;
  }

    table.profit table tr td:nth-child(1) {
      width: 32%;
    }

  .action_btns .sq_btn {
    width: 22px;
    height: 22px;
  }
}

@media(max-width:991px) {
  .full-screen-search {
    width: 70%;
  }

  .grey_boxes {
    padding: 30px 40px;
  }

  .chart_div {
    margin-top: 40px;
  }

  .header {
    background-color: #1C1C1C;
  }

    .header div.dropdown > div.caption {
      background-color: transparent;
      color: #fff;
    }

    .header .sides .user_name {
      color: #fff;
    }

    .header .sides .user_image {
      width: 32px;
      height: 32px;
    }

    .header .head {
      display: flex;
      align-items: center;
    }

      .header .head .mobnav_icon {
        display: block;
      }

  .head div.dropdown {
    min-width: inherit;
    padding-right: 20px;
    margin-left: 15px;
  }

  .sidebar {
    margin-left: -250px;
  }

  .main > .data {
    margin-left: 0;
  }

    .main > .data.open {
      margin-left: 0;
    }

  .sidebar.open {
    z-index: 9999;
  }

  .sidebar {
    z-index: 9999;
  }

  .data .data_in .data_row > .row {
    flex-direction: column-reverse;
  }

  .head div.dropdown span.arrow svg path {
    fill: #fff;
  }

  .report_history .rh_outer {
    padding: 20px;
  }

  .rh_in table tr th,
  .rh_in table tr td {
    width: auto;
  }
    /*
    .rh_in table tr th, .rh_in table tr td:nth-child(1) {
        min-width: 220px;
    }
*/
    .rh_in table tr th:nth-child(4),
    .rh_in table tr td:nth-child(4) {
      width: auto;
    }

  .ucr_table.rh_in table tr th:nth-child(5),
  .ucr_table.rh_in table tr td:nth-child(5) {
    width: auto;
  }

  .chart-container {
    height: 350px;
  }

  .pagenum .pg_buttons {
    position: relative;
    right: 0;
    margin-top: 20px;
  }
}

@media(max-width:767px) {
  .header {
    padding: 20px 20px 20px 22px;
  }

  .head div.dropdown {
    min-width: inherit;
    position: relative;
    padding-right: 15px;
  }

  .dr_leftcolm {
    padding-right: 0;
  }

  .data .data_in {
    padding: 20px 30px;
  }

  .grey_boxes {
    padding: 30px 20px;
  }

  .gb_heading {
    flex-direction: column;
  }

    .gb_heading .gbh_right {
      margin-top: 10px;
    }

  .main > .data.open {
    position: relative;
  }

  .cs_in .profitpercent {
    position: relative;
    top: 0;
    margin-top: 20px;
  }

  .cs_details .csd_in {
    margin-top: -40px;
  }

  .cs_arrows .arrow_left {
    left: -5px;
  }

  .cs_arrows .arrow_right {
    right: -5px;
  }

  .tasks .heading .sa {
    position: relative;
    top: 0;
    margin-top: 10px;
  }

  .full-screen-search {
    width: 100%;
  }

    .full-screen-search input.full-screen-search__input {
      font-size: 20px;
      padding: 20px 30px 20px 20px;
    }

    .full-screen-search button.full-screen-search__btn {
      width: 40px;
      top: 10px;
      height: 40px;
    }

  .header .sides .user_name {
    font-size: 12px;
  }

  .header .sides .user_image {
    width: 25px;
    height: 25px;
  }

  .header div.dropdown > div.caption {
    font-size: 12px;
  }

  .header div.dropdown > div.list > div.item {
    font-size: 12px;
  }

  .sidebar_menu li span {
    font-size: 14px;
  }

  .sidebar.open .sidebar_menu li span.icon {
    margin-right: 10px;
  }

  .stopbar .logo img {
    width: 35px;
  }

  .sidebar_menu li {
    padding: 5px 20px 5px 20px;
  }

  .stopbar {
    padding: 20px;
  }

  .sidebar_menu li span.icon svg {
    height: 20px;
  }

  .rb_in .data {
    padding-right: 10px;
  }

  .ucr_table.rh_in table {
    table-layout: auto;
  }

  .rh_in table tr th,
  .rh_in table tr td:nth-child(1) {
    min-width: inherit;
  }

  .pagenum .pg_buttons {
    position: relative;
    left: 0;
    right: 0;
    margin-top: 20px;
  }

  .reports .reports_tab .tab {
    padding-left: 15px;
  }

    .reports .reports_tab .tab .tablinks {
      font-size: 16px;
    }

  .rh_in table tr th,
  .rh_in table tr td {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media(max-width:500px) {
  .reports .reports_tab .tab {
    padding-left: 15px;
  }

    .reports .reports_tab .tab .tablinks {
      font-size: 14px;
      margin-right: 5px;
      padding-right: 5px;
      white-space: nowrap;
    }
}

@media(max-width:400px) {
  .header {
    padding: 15px;
    position: relative;
  }

    .header .sides .user_name {
      font-size: 14px;
    }

    .header div.dropdown > div.caption {
      font-size: 14px;
    }

  .reports .reports_tab .tab {
    grid-template-columns: auto auto auto 1fr 25px 25px 25px 10px;
  }
}


/*Responsive CSS*/

.cs_in .ct-chart {
  height: 350px;
  stroke-linecap: round;
}

#chart1 .ct-slice-donut {
  stroke: #1BD074;
}

#chart2 .ct-slice-donut {
  stroke: #1BD074;
}

#chart3 .ct-slice-donut {
  stroke: #1BD074;
}

.amountcell {
  text-align: left;
}

.amountCellHeader {
  text-align: center !important;
  float: left;
  padding-left: 0px;
}

.datatable-row-even {
  background-color: rgba(244, 244, 244, 0.4);
}

.datatable-row-odd {
  background-color: #FFFFFF;
}


.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
  background-color: inherit;
}

.rh_outer {
  background-color: #FFFFFF !important;
}

.ngx-datatable.material .datatable-summary-row .datatable-body-row {
  background-color: transparent !important;
}

.totaltext {
  font-family: Biotif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 33px;
  color: #505050;
  text-transform: uppercase;
}

.amountcell {
  text-align: center;
}

.menu_dot_icon {
  width: 7px !important;
}
/* .ngx-datatable.material .datatable-header .datatable-header-cell{
    text-align: left !important;
}
.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell{
    text-align: left !important;
} */
/* @media(max-width: 1130px){
    .report_history .rh_outer .rh_in {
        overflow-x: auto;
    }
} */


/* New Css For Sidebar */

.sidebar_menu li {
  padding:10px 15px 10px 15px;
  position: relative;
  margin-bottom: 0px;

}
  .sidebar_menu li.small-icon:before {
    content: '';
    position: absolute;
    left: 133px;
    top: 0;
    width: 50px !important;
    height: 100%;
  }
  .sidebar_menu li.active:before {
    width: 90%;
    z-index: -1;
    background-color: #E9F2FF;
    margin-left: 10px;
    border-radius: 5px;
  }

  .sidebar_menu li span.icon {
    margin-top: -3px;
  }

.sidebar .stopbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 17px 20px 14px;
}

.sidebar.open .stopbar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 22px 20px 22px;
}

.sidebar.open .nav_button,
.sidebar .nav_button {
  position: absolute;
  right: -10px;
  top: 28px;
}

.sidebar .round_toggle,
.sidebar.open .round_toggle {
  height: 25px !important;
  width: 25px !important;
  border: 1px solid #525252;
  border-radius: 50% !important;
  background-color: #fff !important;
  animation-name: popup-toggle;
  animation-duration: 0.8s;
  animation-iteration-count: 1;
  position: relative !important;
  /* background-image: url(); */
}

  .sidebar .round_toggle::after {
    content: '';
    height: 10px;
    width: 2px;
    background-color: #1c1c1c;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(12px, 10px) rotate(45deg);
    border-radius: 5px;
    transition: 0.5s;
  }

  .sidebar .round_toggle::before {
    content: '';
    height: 10px;
    width: 2px;
    background-color: #1c1c1c;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(12px, 4px) rotate(135deg);
    border-radius: 5px;
    transition: 0.5s;
  }

  .sidebar.open .round_toggle::before {
    transform: translate(10px, 4px) rotate(226deg);
  }

  .sidebar.open .round_toggle::after {
    transform: translate(10px, 10px) rotate(-45deg);
  }

@keyframes popup-toggle {
  from {
    transform: scale(0);
    transition: 0.3s;
    opacity: 0;
  }

  to {
    transform: scale(1);
    transition: 0.3s;
    opacity: 1;
  }
}

.edit__toggle {
  visibility: visible;
  opacity: 0;
  cursor: pointer;
  transition: 0.3s;
}

.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .edit__toggle {
  visibility: visible;
  opacity: 1;
}

datatable-scroller.datatable-scroll.ng-star-inserted {
  width: 100% !important;
}

.bottom_menu ul li .text {
  color: #4f4f4f !important;
}

.bottom_menu ul li .logout_text {
  cursor: pointer;
}

.stopbar .logo img {
  transition: 0.5s;
}

  .stopbar .logo img:hover {
    transform: rotate(20deg);
  }

.supplier.ng-star-inserted {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-cell {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

#toggle_sidebar {
  user-select: none;
  cursor: pointer;
  height: 25px;
  width: 25px;
}



/* Responsive table */

.datatable-row-center.datatable-row-group.ng-star-inserted {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.datatable-row-center.ng-star-inserted {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

@media all and (max-width: 768px) {
  .datatable-row-center.ng-star-inserted {
    align-items: flex-start !important;
  }
}

.custom__met__menu {
  overflow: unset !important;
}

.checked__tick img {
  margin-top: 3px;
}

.checked__tick span::after {
  content: '';
  height: 0px;
  width: 0px;
  border-top: 6px solid transparent;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #000;
  transform: rotate(45deg);
  position: absolute;
  right: -6px;
  bottom: -4px;
}

.checked__tick span {
  visibility: hidden;
  position: absolute;
  display: flex;
  top: -36px;
  right: 8px;
  width: max-content;
  background: #000;
  color: #fff;
  border-radius: 5px 5px 0px 5px;
  padding: 3px 15px;
  cursor: pointer;
  transition: 0.3s;
  opacity: 0;
}

.checked__tick:hover span {
  opacity: 1;
  visibility: visible;
}

.action_button img {
  margin-right: 10px;
}

.another_icons {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

  .another_icons img {
    width: 22px;
  }

/* .sidenav_chat{} */
.sidenav_chat .closebtn {
  position: absolute;
  left: -100px;
}

.action_container {
  visibility: hidden;
  background-color: #fff;
  position: absolute;
  right: 10px;
  top: 0;
  border-radius: 7px;
  box-shadow: 2px 2px 30px #d5d5d5;
  transition: 0.3s;
  opacity: 0;
}

  .action_container button {
    width: 200px;
    border: 0px;
    padding: 15px;
    background-color: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: 0.3s
  }

    .action_container button:hover {
      background-color: rgb(240, 240, 240);
    }

.action_container_open {
  opacity: 1;
  visibility: visible;
}

.ngx-datatable.material .datatable-body .datatable-row-detail {
  padding: 0px !important;
}

.center_cell {
  display: flex;
  justify-content: center;
  align-items: center;
}

.outstanding_amount_comp .ngx-datatable.material .datatable-header {
  padding: 0px 10px;
  border-bottom: 1px solid #F4F4F4 !important;
  display: flex;
}

.outstanding_amount_comp .ngx-datatable .datatable-header .datatable-header-cell .datatable-header-cell-template-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

  .outstanding_amount_comp .ngx-datatable .datatable-header .datatable-header-cell .datatable-header-cell-template-wrap:last-child {
    align-items: flex-start !important;
  }

.outstanding_amount_comp .ngx-datatable .datatable-body .datatable-body-row {
  padding: 0px 10px;
}

.outstanding_amount_comp .datatable-body .datatable-body-row .datatable-body-cell:nth-child(2) {
  justify-content: flex-start;
}

.outstanding_amount_comp .datatable-body .datatable-body-row .datatable-body-cell {
  display: flex;
  justify-content: center;
  align-items: center;
}

.outstanding_amount_comp .rh_outer {
  box-shadow: unset !important;
  border-radius: 6px !important;
  /* border-top: 1px solid #F4F4F4 !important; */
  border-bottom: 1px solid #F4F4F4 !important;
  padding: 0 !important;
}

.outstanding_amount_comp .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:nth-child(2) {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.payments_comp .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell:nth-child(1),
.payments_comp .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell:nth-child(3),
.payments_comp .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell:nth-child(5),
.payments_comp .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:nth-child(1),
.payments_comp .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:nth-child(3),
.payments_comp .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:nth-child(5) {
  justify-content: flex-start !important;
}

.payments_comp .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell,
.payments_comp .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
  display: flex !important;
  /* justify-content: center !important; */
  align-items: center !important;
}

.bookkeeping-table .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell:nth-child(1),
.bookkeeping-table .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell:nth-child(2),
.bookkeeping-table .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell:nth-child(4),
.bookkeeping-table .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell:nth-child(5),
.bookkeeping-table .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell:nth-child(6),
.bookkeeping-table .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell:last-child,
.bookkeeping-table .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:nth-child(1),
.bookkeeping-table .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:nth-child(2),
.bookkeeping-table .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:nth-child(4),
.bookkeeping-table .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:nth-child(5),
.bookkeeping-table .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:nth-child(6),
.bookkeeping-table .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:last-child {
  justify-content: flex-start !important;
}

.bookkeeping-table .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell,
.bookkeeping-table .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

  .bookkeeping-table .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell.left-align-column {
    display: flex !important;
    justify-content: left !important;
    align-items: center !important;
  }

  .bookkeeping-table .ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell.left-align-column {
    display: flex !important;
    justify-content: left !important;
    align-items: center !important;
  }

.send_email_select {
  padding: 12px 10px 10px 10px !important;
  border: 0.5px solid #E5E5E5 !important;
  background: #FFFFFF;
  background-image: url(../images/chevron_big_down.png);
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: 11px;
  background-position: 94%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: Biotif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  width: 100% !important;
  letter-spacing: 0px;
  text-align: left;
  color: #9D9D9D;
}

.send_email_input {
  padding: 12px 10px 10px 10px !important;
  border: 0.5px solid #E5E5E5 !important;
  background: #FFFFFF;
  width: 100%;
}

datatable-selection {
  display: flex;
}

.edit__toggle img {
  width: 7px !important;
}

  .edit__toggle img.edit__toggle_picture {
    width: 30px !important;
  }

.clsContStatus {
  display: inline-block;
  background-color: #13FFC6;
  padding: 0px 5px;
  font-size: 10px;
  color: #515c67;
  margin-left: 5px;
}

.disabled {
  opacity: 0.3;
  pointer-events: none;
}
